// Variable's for icons
$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../../fonts/" !default;

$icon-filter: "\e929";
$icon-download: "\e928";
$icon-Manage-Shipping-Address: "\e927";
$icon-PinIcons: "\e926";
$icon-location-tick: "\e927";
// $icon-download: "\e926";
$icon-calendar: "\e924";
$icon-breadcrumbs: "\e925";
$icon-crown: "\e923";
$icon-edit: "\e920";
$icon-xls: "\e921";
$icon-vertical-dot: "\e922";
$icon-star: "\e91f";
$icon-history: "\e91e";
$icon-shopping-bag: "\e91c";
$icon-add: "\e906";
$icon-minus: "\e90b";
$icon-printer: "\e914";
$icon-upload: "\e915";
$icon-element-blocks: "\e916";
$icon-d-rotate: "\e917";
$icon-eye-off: "\e918";
$icon-receipt-item: "\e919";
$icon-eye-on: "\e91a";
$icon-messages: "\e91b";
$icon-logout: "\e91d";
$icon-clock: "\e900";
$icon-info-circleIcons: "\e901";
$icon-sms: "\e902";
$icon-call: "\e903";
$icon-cross: "\e904";
$icon-trash: "\e905";
$icon-search: "\e907";
$icon-d-square: "\e908";
$icon-document-text: "\e909";
$icon-inventory: "\e90a";
$icon-bulk: "\e90c";
$icon-union: "\e90d";
$icon-cart: "\e90e";
$icon-user: "\e90f";
$icon-chevron-up: "\e910";
$icon-chevron-right: "\e911";
$icon-chevron-left: "\e912";
$icon-chevron-down: "\e913";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?edf6mq');
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?edf6mq#iefix') format('embedded-opentype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?edf6mq') format('truetype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?edf6mq') format('woff'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?edf6mq##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-filter {
  &:before {
    content: $icon-filter;
  }
}

.icon-download {
  &:before {
    content: $icon-download;
  }
}

.icon-Manage-Shipping-Address {
  &:before {
    content: $icon-Manage-Shipping-Address;
  }
}

.icon-PinIcons {
  &:before {
    content: $icon-PinIcons;
  }
}

.icon-location-tick {
  &:before {
    content: $icon-location-tick;
  }
}

// .icon-download {
//   &:before {
//     content: $icon-download;
//   }
// }

.icon-calendar {
  &:before {
    content: $icon-calendar;
  }
}

.icon-breadcrumbs {
  &:before {
    content: $icon-breadcrumbs;
  }
}

.icon-crown {
  &:before {
    content: $icon-crown;
  }
}

.icon-edit {
  &:before {
    content: $icon-edit;
  }
}

.icon-xls {
  &:before {
    content: $icon-xls;
  }
}

.icon-vertical-dot {
  &:before {
    content: $icon-vertical-dot;
  }
}

.icon-star {
  &:before {
    content: $icon-star;
  }
}

.icon-history {
  &:before {
    content: $icon-history;
  }
}

.icon-shopping-bag {
  &:before {
    content: $icon-shopping-bag;
  }
}

.icon-add {
  &:before {
    content: $icon-add;
  }
}

.icon-minus {
  &:before {
    content: $icon-minus;
  }
}

.icon-printer {
  &:before {
    content: $icon-printer;
  }
}

.icon-upload {
  &:before {
    content: $icon-upload;
  }
}

.icon-element-blocks {
  &:before {
    content: $icon-element-blocks;
  }
}

.icon-d-rotate {
  &:before {
    content: $icon-d-rotate;
  }
}

.icon-eye-off {
  &:before {
    content: $icon-eye-off;
  }
}

.icon-receipt-item {
  &:before {
    content: $icon-receipt-item;
  }
}

.icon-eye-on {
  &:before {
    content: $icon-eye-on;
  }
}

.icon-messages {
  &:before {
    content: $icon-messages;
  }
}

.icon-logout {
  &:before {
    content: $icon-logout;
  }
}

.icon-clock {
  &:before {
    content: $icon-clock;
  }
}

.icon-info-circleIcons {
  &:before {
    content: $icon-info-circleIcons;
  }
}

.icon-sms {
  &:before {
    content: $icon-sms;
  }
}

.icon-call {
  &:before {
    content: $icon-call;
  }
}

.icon-cross {
  &:before {
    content: $icon-cross;
  }
}

.icon-trash {
  &:before {
    content: $icon-trash;
  }
}

.icon-search {
  &:before {
    content: $icon-search;
  }
}

.icon-d-square {
  &:before {
    content: $icon-d-square;
  }
}

.icon-document-text {
  &:before {
    content: $icon-document-text;
  }
}

.icon-inventory {
  &:before {
    content: $icon-inventory;
  }
}

.icon-bulk {
  &:before {
    content: $icon-bulk;
  }
}

.icon-union {
  &:before {
    content: $icon-union;
  }
}

.icon-cart {
  &:before {
    content: $icon-cart;
  }
}

.icon-user {
  &:before {
    content: $icon-user;
  }
}

.icon-chevron-up {
  &:before {
    content: $icon-chevron-up;
  }
}

.icon-chevron-right {
  &:before {
    content: $icon-chevron-right;
  }
}

.icon-chevron-left {
  &:before {
    content: $icon-chevron-left;
  }
}

.icon-chevron-down {
  &:before {
    content: $icon-chevron-down;
  }
}