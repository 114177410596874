.footer {
  height: 124px;
  background-color: $border-color;

  @include media(down, $breakpoint-md) {
    // height: 162px;
  }

  @include media(down, $breakpoint-sm) {
    // height: 214px;
  }
}

.footer-wrap {
  padding: 12px 0;

  .brand-logo-list {
    flex-basis: auto;
    opacity: 0;

    @include media(down, $breakpoint-md) {
      display: none !important;
    }

    li {
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  .footer-logo {
    img{
      height: 30px;
    }
    flex: 0 0 auto;

    @include media(down, $breakpoint-md) {
      display: flex;
      flex: 1 1 auto !important;
      justify-content: end;
    }
  }

  .footer-navigation {
    @include media(down, $breakpoint-md) {
      max-width: 296px;
    }
  }
}

.footer-nav {
  @include media(down, $breakpoint-sm) {
    // flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .nav-item {
    padding: 0;

    &:not(:last-child) {
      margin-right: 24px;
    }

    .nav-link {
      color: $dark;
      @include fs-16;
      padding: 0;
      font-weight: $font-medium;

      &:hover {
        color: $secondary;
      }
    }
  }
}

.copyright {
  padding: 20px 0;
  background-color: $white;

  span {
    color: #8A8C97;
    @include fs-14;
  }
}