// Begin: Button
.btn {
  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $line-height-base, $box-shadow-0);
  font-weight: $font-bold;

  &.btn-sm {
    //font-size: $font-size-sm;
    //font-size:14px;
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $line-height-base, $box-shadow-0);
  }

  &.btn-lg {
    //font-size: $font-size-lg;
    //font-size:size(20);
    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $line-height-base, $box-shadow-0);
  }

  &:not(:disabled):not(.disabled):active:focus,
  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
    outline: none;
  }

  &.btn-primary {
    padding: 10px 12px;
    // background: linear-gradient(91.23deg, $secondary 0.54%, $dark-blue 100%);
    background-color: $primary;
    border-radius: 5px;
    color: $white;
    @include fs-16;
    // line-height: 22px;
    font-weight: $font-bold;
    border-width: 1px;
    @include css3-prefix(transition, all .4s ease-in-out);

    @include media(down, $breakpoint-xxl) {
      padding: 6px 12px;
    }

    &:hover,
    &:focus,
    &:active {
      // background: linear-gradient(91.23deg, #04207B .54%, #226CB4 100%);
      outline: none;
      box-shadow: 0px 4px 30px rgba(40, 41, 61, 0.08);
    }
  }

  &.btn-icon {
    display: flex;
    align-items: center;

    .icon {
      font-size: 20px;
      margin-right: 10px;
    }
  }

  &.btn-outline-primary {
    padding: 10px 12px;
    // color: $primary;
    // border-color: $dark-blue;
    // color: $dark-blue;
    @include fs-16;

    // line-height: 27px;
    @include media(down, $breakpoint-xxl) {
      padding: 6px 12px;
    }

    &:not(:disabled):not(.disabled):active,
    &:hover,
    &:focus,
    &:active {
      color: $primary;
      background-color: transparent;
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
      // background: linear-gradient(91.23deg, $secondary 0.54%, $dark-blue 100%);
    }

    &.text-color {
      &:focus {
        color: $black;
      }
    }
  }

  &.btn-outline-dark {
    border-color: #DFDFDF;
    border-radius: 5px;
    line-height: 25px;

    &:not(:disabled):not(.disabled):active:focus,
    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
    }
  }

  &.btn-icon-only {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    background-color: rgba(63, 81, 181, 0.1);
    border: none;
    border-radius: 100px;

    .icon {
      font-size: 16px;
      color: $dark;
    }
  }
}


/** ---------------------------------------- FORM CONTROL ---------------------------------------- **/
textarea {
  resize: none;
}

.form-group {
  margin-bottom: 22px;

  .form-label {
    margin-bottom: 4px;
    @include fs-16;
    font-weight: $font-regular;

    sup {
      top: 0;
      color: $danger;
      @include fs-16;
    }
  }
}

.form-control {
  // height: 56px;
  padding: 13px 20px;
  background-color: $white;
  border-color: $light;
  color: $dark;
  @include fs-16;
  font-weight: $font-regular;
  cursor: pointer;
  @include css3-prefix(border-radius, 4px);

  @include placeholder {
    color: #8A8C97;
    font-weight: $font-regular;
  }

  &.focus,
  &:focus {
    box-shadow: none;
    border-color: $dark;

    // @include placeholder {
    //   color: $dark-blue;
    // }
  }

  &[readonly] {
    color: $gray;
  }
}

.has-danger {
  // color: $danger;

  .form-control,
  .custom_select__control {
    border-color: $danger;
  }
}

.error-block {
  .form-control {
    border-color: $danger;
  }

  .form-text {
    color: $danger;
  }
}

// DROPDOWN
.dropdown {
  .btn {
    position: relative;

    &.dropdown-toggle {
      &::after {
        content: "\e90e";
        position: absolute;
        right: -15px;
        top: 4px;
        border: none;
        color: $dark;
        font-family: 'icomoon' !important;
        font-size: 6px;
      }
    }

    .icon-btn {
      font-size: 20px;
      vertical-align: text-top;
    }
  }

  .dropdown-menu {
    box-shadow: -7px 8px 16px 0px rgba(55, 70, 95, 0.2);
    background: $white;
    border-color: $border-color;

    &::before {
      z-index: -1;
      content: "";
      position: absolute;
      top: -1px;
      right: 1.2rem;
      display: block;
      height: 0.75rem;
      width: 0.75rem;
      background: $white;
      border-top: 1px solid $border-color;
      border-left: 1px solid $border-color;
      box-sizing: border-box;
      transform: rotate(45deg) translate(-7px);
    }

    &.dropdown-icon {
      .dropdown-item {
        display: flex;
        align-items: center;
        padding: 0.62rem 1.3rem;
        color: $dark;

        &:active {
          background: $light !important;
          color: $dark;
        }

        &:hover {
          background: $light !important;
          color: $dark;
        }

        &:focus {
          background: $light;
          color: $dark;
        }

        span {
          @include fs-18;
          color: $dark;
          font-weight: $font-regular;
          text-transform: uppercase;
        }
      }
    }
  }

  // .dropdown-toggle {
  //   &.nav-link {
  //     &:after {
  //       display: none;
  //     }
  //   }
  // }
}

/** ---------------------------------------- CUSTOM CHECKBOX ---------------------------------------- **/
.custom-checkbox {
  &.vertical {
    label {
      display: block;
    }
  }

  label {
    // margin: 0 10px 10px 0;
    position: relative;
    display: inline-block;
    padding: 0 0 0 40px;
    @include fs-18;
    cursor: pointer;
  }

  input[type="checkbox"] {
    display: none;

    +span {
      content: "";
      position: absolute;
      top: 2px;
      left: 0;
      display: inline-block;
      height: 24px;
      width: 24px;
      background: $white;
      border: 2px solid $light;
      border-radius: $border-radius;
    }

    &:checked {
      +span {
        border: 1px solid $secondary;
        background: $secondary;
        @include css3-prefix(transition, all 0.3s);
      }

      ~span:after {
        top: 50%;
        left: 50%;
        height: 13px;
        width: 7px;
        margin-left: -4px;
        margin-top: -8px;
        border-width: 0 3px 3px 0 !important;
        display: block;
        border: solid $white;
        @include css3-prefix(transform, rotate(45deg));
      }
    }

    ~span:after {
      display: none;
      position: absolute;
      content: "";
    }

    &:disabled {
      +span {
        opacity: 0.7;
      }
    }
  }

}

.custom-radio {
  &.vertical {
    label {
      display: block;
    }
  }

  label {
    display: inline-block;
    padding: 0 0 0 40px;
    position: relative;
    cursor: pointer;
  }

  input[type="radio"] {
    display: none;

    +span {
      content: "";
      position: absolute;
      // top: 50%;
      top: 0px;
      left: 0;
      display: inline-block;
      height: 24px;
      width: 24px;
      background: $white;
      border: 2px solid #9E9E9E;
      border-radius: 50%;
      // @include css3-prefix(transform, translateY(-50%));
    }

    ~span:after {
      display: none;
      position: absolute;
      content: "";
    }

    &:disabled {
      +span {
        opacity: 0.5;
        cursor: not-allowed;

        +span {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }

    &:checked {
      +span {
        background: $secondary;
        border: 2px solid $secondary;
        @include css3-prefix(transition, all 0.3s);
      }

      ~span:after {
        top: 50%;
        left: 50%;
        margin-left: -9px;
        margin-top: -9px;
        width: 18px;
        height: 18px;
        display: block;
        border: 2px solid $white;
        background: $secondary;
        @include border-radius(50%);
      }
    }
  }
}

.ie8 .custom-checkbox input[type="checkbox"],
.ie8 .custom-radio input[type="radio"] {
  display: inline-block;
}

.ie8 .custom-checkbox span,
.ie8 .custom-radio span {
  display: none;
}

/** ---------------------------------------- ICONS FIELD ---------------------------------------- **/
.field-icon {
  position: relative;

  .form-control {
    padding: 10px 10px 10px 40px;
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 12px;
    font-size: 20px;
    @include css3-prefix(transform, translateY(-50%));

    &::before {
      color: $dark;
    }
  }

  .clear-search {
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    font-weight: $font-regular !important;
    text-decoration: none;

    @include media(down, $breakpoint-md) {
      width: auto !important;
    }

    &:hover {
      text-decoration: none;
    }

    .icon {
      position: inherit;
      top: inherit;
      left: inherit;
      font-size: 10px;
      transform: inherit;

      &::before {
        color: $dark !important;
      }
    }
  }

  &.right-icon {
    .form-control {
      padding: 10px 40px 10px 10px;
    }

    .icon {
      left: auto;
      right: 12px;
    }
  }
}


/** ---------------------------------------- REACT DATE-PICKER ---------------------------------------- **/
.react-datepicker-popper {
  padding: 0 !important;
}

.react-datepicker {
  border: none;
  box-shadow: 0px 1px 6px -4px var(--dark);
  @include media(up, $breakpoint-lg) {
    padding: 3px 0;
  }
  @include media(down, $breakpoint-lg) {
    padding: 6px 0;

    .react-datepicker__month {
      .react-datepicker__day {
        width: 1.5rem;
        line-height: 1.5rem;
      }
    }
  }

  .react-datepicker__header {
    background-color: var(--primary);
    border: none;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    margin-bottom: 8px;
    color: var(--white);
    @include fs-16;
  }

  .react-datepicker__day-name {
    color: var(--white);
  }

  select {
    height: 24px;
    border-radius: 4px;
    border: none;
    @include fs-14;
    padding: 0 5px;
    outline: none;
  }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-color: var(--white);
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected {
    background-color: var(--primary);
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__navigation {
    top: 4px;
  }

  .react-datepicker__time-container {
    border-color: var(--border-color);

    .react-datepicker-time__header {
      margin-bottom: 0;
    }

    .react-datepicker__time-list-item--selected {
      background-color: var(--primary) !important;
      line-height: 1.7;
    }
  }
}

.manage-label {
  font-size: 18px;
}