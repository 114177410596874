.news-content {
  .news-list {
    border-radius: 4px;
    padding: 16px;
    max-height: inherit;
    border: 1px solid $border-color;
    width: 100%;
    margin: 15px;
    // max-width: 407px;
    flex: 0 0 calc(100% / 3 - 30px);

    @include media(down, $breakpoint-md) {
      flex: 0 0 calc(100% / 1 - 30px);
    }

    &:hover {
      box-shadow: 2px 4px 30px rgba(0, 0, 0, 0.07);
    }

    .news-heading {
      h5 {
        @include fs-18;
        // @include media(up, $breakpoint-xxl) {
        //   line-height: 1.4;
        // }
      }
    }

    .news-time {
      min-height: 32px;
    }

    .news-para {
      margin-top: 10px;

      p {
        @include fs-16;

        @include media(up, $breakpoint-xxl) {
          line-height: 1.4;
          min-height: 70px;
          overflow: hidden;
        }

        @include media(down, $breakpoint-xxl) {
          line-height: 1.4;
          min-height: 70px;
          overflow: hidden;
        }

        @include media(down, $breakpoint-xl) {
          line-height: 1.5;
          height: 100vh;
          max-height: 128px;
          overflow: hidden;
        }

        @include media(down, $breakpoint-lg) {
          line-height: 1.5;
          height: 100vh;
          max-height: 128px;
          overflow: hidden;
        }

        @include media(down, $breakpoint-md) {
          line-height: 1.5;
          height: auto;
          max-height: inherit;
          overflow: hidden;
        }
      }
    }

    .read-more {
      @include fs-16;
    }
  }

  &.news-details {
    .news-list {
      flex: 0 0 calc(100%);

      .news-heading {
        h5 {
          max-width: inherit;
          text-transform: capitalize;
        }
      }

      .news-para {
        p {
          margin-bottom: revert;
          @include fs-16;
          max-height: unset;
          overflow: unset;
          height: inherit;

          a {
            color: $secondary;
          }
        }
      }
    }
  }

  .news-box {
    // min-height: 364px;
  }
}

.add-news {
  .upload-files {
    .btn {
      display: none;
    }
  }

  .upload-title {
    @include fs-16;
  }

  .dropzone {
    margin: 4px 0;
  }
}