.update-shipto {
  .search-bar {
    margin: 0;

    .form-group {
      @include media(down, $breakpoint-xl) {
        margin: 0;
      }
    }
  }

  .select-option {
    max-width: 300px;

    @include media(down, $breakpoint-md) {
      max-width: inherit;
    }
  }

  .custom_select__control {
    // padding: 0 24px 0 12px;
    // padding-left: 10px;
    height: 50px;

    padding: {
      left: 10px;
      top: 0;
      bottom: 0;
    }

    @include media(down, $breakpoint-xxl) {
      height: 40px;
    }

    .custom_select__placeholder {
      line-height: 1.2;
    }
  }

  .btn-primary {
    min-width: 115px;
  }

  .custom_select__menu {
    .custom_select__menu-list {
      .custom_select__option {
        @include fs-16;
      }
    }
  }
}

.update-shipto-modal {
  .custom_select__menu {
    .custom_select__menu-list {
      .custom_select__option {
        @include fs-16;
      }
    }
  }
}


.search-with-btn {
  .form-group {
    @include media(down, $breakpoint-lg) {
      flex: 0 0 240px;
    }
    @include media(down, $breakpoint-md) {
      flex: 0 0 100%;
      margin: 1rem 0 0 !important;
    }
    .form-control {
      width: 100%;
      min-width: 300px;
      height: 50px;

      @include media(down, $breakpoint-xxl) {
        height: 42px;
      }

      @include media(down, $breakpoint-lg) {
        min-width: inherit;
      }
    }
  }

  .field-icon {
    margin-right: 20px;

    @include media(down, $breakpoint-md) {
      margin-right: 0;
    }
  }

  .btn-outline-primary {
    min-width: 150px;

    @include media(down, $breakpoint-xxl) {
      min-width: 130px;
    }
  }

  .btn-primary {
    min-width: 135px;

    @include media(down, $breakpoint-xxl) {
      min-width: 115px;
    }

    @include media(down, $breakpoint-md) {
      min-width: 115px;
      justify-content: end;
    }
  }
}



.update-shipto-content {
  .custom-table {
    tbody {
      tr {
        &:hover {
          .icon {
            color: #F17834;
          }
        }
      }
    }
  }
}