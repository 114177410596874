.on-boarding-form {
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  .left-content {
    background-color: #E9F1F8;
  }
  .right-content {
    padding: 110px 80px;
  }
}
.on-boarding-content {
  .top-heading {
    margin-bottom: 42px;
    h2 {
      margin-bottom: 10px;
      font-size: 22px;
      line-height: 33px;
    }
    span {
      @include fs-18;
    }
  }
  .field-icon {
    .icon-eye-on {
      font-size: 1.35rem;
    }
  }
  .no-boarding-btn {
    margin-top: 30px;
  }
}

.login-products-img {

  .col {

    max-height: 250px;

    @include media(down, $breakpoint-xl) {

          max-height: 200px;

    }

    .card {
      max-height: 250px;
      @include media(down, $breakpoint-sm) {

        max-height: 190px;

        }

      .card-body {

        @include media(down, $breakpoint-sm) {

          align-items: start !important;

        }

 

        img {

          height: 100%;

        }

      }

    }

  }

}