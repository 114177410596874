.dropzone {
    margin: 20px 0;
    padding: 56px;
    text-align: center;
    background: rgba(233, 241, 248, 0.5);
    border: 1px dashed $secondary;
    border-radius: 10px;
    @include media(down, $breakpoint-sm) {
        padding: 20px;
    }
    .icon-upload {
        font-size: 50px;
        color: $secondary;
        @include media(down, $breakpoint-sm) {
            font-size: 40px;
        }
    }
    p {
        @include fs-16;
        color: #9B979B;
    }
    .btn {
        margin-top: 30px;
        padding: 9px 12px;
        min-width: 148px;
        @include media(down, $breakpoint-sm) {
            margin-top: 0;
        }
    }
}

.alignImsOrderCount {
    text-align: center;


}

.uploader-preview {
    flex-wrap: wrap;
    margin: 0 -15px;
    .upload-box {
        flex: 0 0 calc(50% - 30px);
        margin: 0 15px 30px;
        padding: 22px 26px;
        box-shadow: 2px 4px 30px rgba(0, 0, 0, 0.07);
        border-radius: 4px;
        @include media(down, $breakpoint-sm) {
            flex: 1 1 100%;
            margin: 0 15px 22px;
            padding: 15px;
        }
    }
    .left-content {
        .icon {
            margin-right: 20px;
            font-size: 38px;
            color: $dark;
            @include media(down, $breakpoint-sm) {
                margin-right: 10px;
                font-size: 32px;
            }
        }
    }
    .file-name {
        p {
            margin-bottom: 5px;
            @include fs-18;
            @include media(down, $breakpoint-sm) {
                max-width: 120px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                line-height: 1.4;
            }
        }
        span {
            @include fs-16;
            color: #9B979B;
        }
    }

    .right-content {
        .icon-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            width: 40px;
            padding: 0;
            background: rgba(63, 81, 181, 0.1);
            border-radius: 80px;
            @include media(down, $breakpoint-sm) {
                height: 30px;
                width: 30px;
            }
            &:hover {
                background-color: rgba($danger, 0.5);
                .icon {
                    color: $white;
                }
            }
            .icon {
                font-size: 12px;
                @include media(down, $breakpoint-sm) {
                    font-size: 10px;
                }
            }
        }
    }
}
.download-link {
    color: $secondary;
    @include fs-16;
    margin-right: 24px;
}