.reports-order {
  .card {
    border-color: $border-color;
    border-radius: 4px;
  }
  .card-header {
    background-color: $border-color;
    padding: 12px 40px 12px 12px;
    border: none;
  }
  .card-body {
    padding: 22px 30px 22px 15px;
  }
}
.report-list {
  li {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
  .btn {
    // min-width: 85px;
    // padding: 6px 12px;
    @include fs-16;
    &:hover {
      text-decoration: none;
      color: $dark-blue;
    }
    .text-decoration {
      text-decoration: underline;
    }
    .icon {
      @include fs-16;
      font-weight: $font-bold;
    }
  }
}
.report-content {
  .icon{
    margin-right: 12px;
    font-size: 22px;
    color: $secondary;
  }
}