.modal-dialog {
  margin: 150px auto;

  @include media(down, $breakpoint-xxl) {
    margin: 50px auto;
  }

  @include media(down, $breakpoint-sm) {
    padding: 0 10px;
  }

  .modal-header {
    .close {
      &:not(:disabled):not(.disabled):hover,
      &:hover {
        opacity: 1;
        // background-color: $light;
      }
    }
  }
}

.small-popup-width {
  .modal-dialog {
    @include media(down, $breakpoint-lg) {
      max-width: 990px;
      width: 80%;
    }

    @include media(down, $breakpoint-sm) {
      max-width: 990px;
      width: auto;
    }
  }

  &.product-details-modal {
    .modal-dialog {
      @include media(down, $breakpoint-lg) {
        max-width: 990px;
        width: 90%;
      }
      @include media(down, $breakpoint-md) {
        max-width: 990px;
        width: auto;
      }
    }
  }
}

.product-details-modal {
  .modal-header {
    padding: 0;
    border: none;
  }

  .close {
    z-index: 1;
    opacity: 1;
    position: absolute;
    top: -4px;
    right: 30px;
    left: auto;
    display: flex;
    height: 40px;
    width: 40px;
    padding: 0;
    justify-content: center;
    align-items: center;
    background: $white;
    border-radius: 100%;
    box-shadow: 0px 4px 24px rgb(0 0 0 / 20%);
    cursor: pointer;

    &::after {
      content: "\e904";
      font-family: $icomoon-font-family;
      @include fs-14;
    }

    span {
      display: none;
    }
  }

  .modal-dialog {
    max-width: 1070px;

    @include media(down, $breakpoint-xxl) {
      max-width: 990px;
    }
  }

  .modal-body {
    padding: 30px;

    @include media(down, $breakpoint-sm) {
      padding: 20px 15px;
    }
  }

  .modal-heading {
    margin-bottom: 13px;

    h3 {
      @include fs-16;
      line-height: 1.2;
      text-align: left;
    }
  }
}

.product-details {
  .left-content {
    flex: 0 0 287px;
    max-width: 287px;

    // @include media(down, $breakpoint-lg) {
    //   flex: 1 1 70%;
    //   max-width: 70%;
    // }
    @include media(down, $breakpoint-tab) {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }

  .right-content {
    flex: 1 1 100%;
    margin-left: 20px;

    @include media(down, $breakpoint-tab) {
      margin: 20px 0 0;
    }
  }
}

.eqi-content {
  margin-bottom: 8px;

  .product-color {
    margin-left: 10px;
    padding: 5px 8px;
    border: 1px solid $light;
    border-radius: 4px;

    .grey-color {
      display: inline-block;
      height: 14px;
      width: 14px;
      // background-color: $gray;
      border-radius: 2px;
      margin-right: 6px;
    }

    .color-title {
      @include fs-14;
      text-transform: capitalize;
      font-weight: $font-medium;
    }
  }
}

.eqi-value {
  @include fs-16;
  color: $gray;

  span {
    color: $dark;
  }
}

// update-shipto-modal
.update-shipto-modal {
  @extend .product-details-modal;

  .modal-dialog {
    max-width: 845px;

    .modal-content {
      padding: 30px;

      .modal-header {
        padding: 8px 0px 30px;
        border: none;
        @include fs-20;
        font-weight: $font-bold;
      }

      .modal-body {
        padding: 0px;
      }
    }
  }

  .form-group {
    .btn {
      min-width: 148px;
    }
  }
}

// Accordion
.item-specification {
  margin-top: 15px;

  .item-dropdown {
    margin-right: 10px;
  }

  .btn {
    flex: 0 0 auto;
  }

  .accordion-item {
    border: 1px solid #dfdfdf;
    border-radius: 5px;

    .card-header {
      background-color: transparent;
      padding: 13px 20px;

      @include media(down, $breakpoint-xxl) {
        padding: 8px 20px;
      }

      @include media(down, $breakpoint-sm) {
        padding: 8px 14px;
      }

      border: none;
      cursor: pointer;

      h4 {
        font-size: 15px;
        line-height: 22px;
      }

      .icon {
        font-size: 22px;
        @include css3-prefix(transition, all 0.4s ease-in-out);

        &.active {
          @include css3-prefix(transform, rotate(90deg));
        }
      }
    }
  }
}

.specification-list {
  padding: 20px;
  border-top: 1px solid #dfdfdf;

  @include media(down, $breakpoint-sm) {
    padding: 14px;
  }

  .list-items {
    width: 100%;

    &:not(:last-child) {
      margin-right: 10px;
      flex: 0 0 412px;

      @include media(down, $breakpoint-xxl) {
        flex: 0 0 370px;
      }

      @include media(down, $breakpoint-xl) {
        flex: 0 0 100%;
      }

      @include media(down, $breakpoint-sm) {
        flex: 0 0 auto;
      }
    }

    li {
      display: flex;
      align-items: start;
      padding: 15px 0;
      border-bottom: 1px solid #dfdfdf;

      &:first-child {
        padding-top: 0;
      }

      h5 {
        flex: 0 0 168px;

        @include media(down, $breakpoint-xxl) {
          flex: 0 0 152px;
        }

        &.title-dimensions {
          flex: 0 0 250px;

          @include media(down, $breakpoint-xxl) {
            flex: 0 0 220px;
          }

          @include media(down, $breakpoint-sm) {
            flex: 0 0 200px;
          }
        }
      }

      h5,
      span {
        @include fs-16;

        label {
          @include fs-14;

          @include media(down, $breakpoint-xl) {
            // margin-top: 6px !important;
          }

          @include media(down, $breakpoint-sm) {
            // margin-top: 1px !important;
          }
        }
      }
    }
  }
}

// Proceed Checkout Modal
.proceed-checkout-modal {
  @extend .product-details-modal;

  .modal-dialog {
    max-width: 845px;
  }

  .modal-header {
    padding: 38px 30px 10px;

    .modal-title {
      @include fs-20;
    }
  }

  .add-standard {
    margin-bottom: 20px;

    .btn-link {
      // margin-bottom: 5px;
      color: $dark-blue;
      line-height: 30px;
    }

    p {
      margin-bottom: 0;
      @include fs-16;
    }
  }

  .warning-text {
    display: block;
    margin: 20px 0;
    color: $warning;
    @include fs-16;
  }

  .order-place {
    margin-top: 30px;

    .btn {
      width: 100%;
      max-width: 198px;

      @include media(down, $breakpoint-md) {
        width: 35% !important;
      }
    }
  }
}

.standard-delivery-modal {
  @extend .proceed-checkout-modal;

  .notes {
    display: block;
    margin-top: 20px;
    color: $secondary;
  }

  .order-place {
    .btn {
      max-width: 128px;
    }
  }
}

// Small modal
.sm-modal {
  .modal-sm {
    max-width: 406px;

    @include media(down, $breakpoint-md) {
      width: 90%;
    }
  }

  .modal-body {
    padding: 30px;
  }

  .sm-modal-content {
    margin-bottom: 20px;

    .page-title {
      @include fs-20;
    }
  }

  .foot-btn {
    .btn {
      min-width: 147px;
      padding: 9px 15px;
      line-height: 22px;

      @include media(down, $breakpoint-md) {
        min-width: 124px;
      }
    }
  }
}

.cancel-orders-list-modal {
  .close {
    z-index: 1;
    opacity: 1;
    position: absolute;
    top: -4px;
    right: 30px;
    left: auto;
    display: flex;
    height: 40px;
    width: 40px;
    padding: 0;
    justify-content: center;
    align-items: center;
    background: $white;
    border-radius: 100%;
    box-shadow: 0px 4px 24px rgb(0 0 0 / 20%);
    cursor: pointer;

    &::after {
      content: "\e904";
      font-family: $icomoon-font-family;
      @include fs-14;
    }

    span {
      display: none;
    }
  }

  .modal-dialog {
    max-width: 1550px;

    @include media(down, $breakpoint-xxl) {
      max-width: 990px;
    }
  }

  .modal-body {
    padding: 30px;

    @include media(down, $breakpoint-sm) {
      padding: 20px 15px;
    }
  }

  .modal-heading {
    margin-bottom: 13px;

    h3 {
      @include fs-16;
      line-height: 1.2;
      text-align: left;
    }
  }
}
