/**
* ----------------------------------------
* Main Page style
* ----------------------------------------
*/
.page-title {
  color: $dark;
  font-size: 22px;
  line-height: 33px;
  font-weight: $font-bold;

  @include media(down, $breakpoint-sm) {
    font-size: 18px;
    line-height: 1.4;
  }
}

/**
* ----------------------------------------
* Catalog list
* ----------------------------------------
*/
.catalog-section {
  margin-bottom: 40px;

  &.catalogue-pagination {
    .grid-pagination {
      border: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.catalog-wrap {
  .filter-bar {
    flex: 0 0 342px;
    max-width: 342px;
    padding: 12px;
    border: 1px solid $border-color;
    border-radius: 4px;

    @include media(down, $breakpoint-xxl) {
      flex: 0 0 298px;
      max-width: 298px;
    }
@include media(down, $breakpoint-xl) {
      flex: 0 0 360px;
      max-width: 360px;
    }
    @include media(down, $breakpoint-lg) {
      z-index: 9999;
      position: fixed;
      top: 0;
      left: -100%;
      height: 100vh;
      width: 260px;
      padding: 40px 15px 15px;
      background-color: $white;
      align-items: flex-start;
      @include css3-prefix(transition, all 0.2s ease-in-out);
      overflow-y: auto;

      &.d-block {
        left: 0;
      }
    }

    @include media(down, $breakpoint-lg) {
      width: 100%;
      max-width: 60%;
    }

    @include media(down, $breakpoint-sm) {
      flex: 0 0 100%;
      // max-width: 100%;
      margin-bottom: 1.5rem;
      // width: 260px;
      width: 100%;
      max-width: 95%;
    }

    h3 {
      margin-bottom: 12px;
      // @extend .font-18;
      font-weight: $font-bold;
    }
  }
}

.catalog-list {
  flex: 1 1 100%;
  margin-left: 30px;

  @include media(down, $breakpoint-lg) {
    margin-left: 0;
    width: 100%;
  }

  .inner-wrap {
    &.row {
      margin-right: -10px;
      margin-left: -10px;

      [class^="col-"] {
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 20px;
      }
    }

    .card {
      position: relative;
      top: 0;
      border: $border-width solid $light;
      border-radius: $border-radius;
      padding: 0.75rem;
      height: 100%;
      @include css3-prefix(transition, all 0.4s ease-in-out);
      // &:hover {
      //   top: -4px;
      //   // box-shadow: 0 3px 6px 0 rgba($dark, .20);
      // }

      .select-card-link {
        right: 8px;
        top: 15px;
        z-index: 2;

        @include media(down, $breakpoint-xxl) {
          right: 8px;
          top: 18px;
        }

        .icon {
          color: $secondary;
          font-size: 24px;

          @include media(down, $breakpoint-xxl) {
            font-size: 20px;
          }
        }
      }

      .selected-card {
        +.select-card-link {
          .btn {
            span {
              color: #fff;
            }
          }
        }
      }

      .eqi-place {
        .lable-n-value {
          // margin-bottom: 5px;

          label {
            color: $gray;
            @include fs-14;
            font-weight: $font-regular;
            margin-right: 5px;
            margin-bottom: 0;
          }

          .label-value {
            color: $dark;
            @include fs-14;
            font-weight: $font-medium;
          }
        }

        .product-place {
          color: $dark;
          @include fs-14;
          font-weight: $font-medium;
        }
      }

      .card-body {
        padding: 20px 0 0;

        .card-title {
          color: $dark;
          @include fs-16;
          font-weight: $font-semibold;
          line-height: 1.3;
        }

        .product-caption {
          @include fs-14;
          font-weight: $font-medium;
          color: $primary;
          margin: 5px 0;
        }

        .color-select-wrap {
          .color-box {
            width: 20px;
            height: 20px;
            // background: $gray;
            border-radius: 3px;
            margin-right: 10px;
          }

          .color-text {
            border: 0;
            outline: 0;
            @include fs-14;
            font-weight: $font-medium;
          }
        }

        .product-price {
          @include fs-18;
          font-weight: $font-semibold;
          color: $primary;
          margin: 12px 0 6px;
        }

        .btn {
          margin-top: 12px;
        }
      }

      .selected-card {
        position: absolute;
        top: 0;
        left: 0;

        .card {
          // top: -4px;
          width: 100%;
          background-color: $secondary;
          // border-radius:
          border-color: $secondary;
          padding: 12px 12px 20px;
          color: $white;

          .eqi-place {
            width: 100%;
            margin: 4px 0 0;

            .lable-n-value {
              label {
                color: RGBA(255, 255, 255, 0.70);
              }

              .label-value {
                color: $white;
              }
            }

            .price {
              flex-wrap: wrap;
              margin-bottom: 15px;
              // &:not(:last-child) {
              // }

              .card-title {
                @include fs-18;
                font-weight: $font-semibold;
                margin-bottom: 0;
                flex: 0 0 170px;
                // Need to remove following 3 lines after dyamic changes //
                // white-space: nowrap;
                // text-overflow: ellipsis;
                // overflow: hidden;
              }

              .qty {
                // flex: 1 0 33%;

                .qty-circle {
                  width: 30px;
                  height: 30px;
                  // margin: 0 6px;
                  background: transparent;
                  border: 1px solid $white;
                  border-radius: 50%;
                  box-shadow: none;
                  transition: all 0.5s;
                  cursor: pointer;

                  .icon {
                    font-size: 1rem;
                  }
                }
              }

              .product-place {
                // margin-top: -5px;
                @include fs-14;
                font-weight: $font-regular;
                color: $white;
                flex-basis: 100%;
              }
            }
          }

          .product-view-btn {
            margin-top: 10px;

            button {
              cursor: pointer;
              @include fs-16;
              font-weight: $font-bold;
              text-decoration: $link-hover-decoration;
              color: $white;
              background: none;
              border-width: 0;
            }
          }
        }
      }
    }
  }

  .img-item {
    height: 185px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .card-img-top {
      // min-height: 185px;
      max-height: 185px;
      max-width: 185px;
    }
  }
}

.product-items {
  padding-top: 15px;
  border-top: 1px solid $border-color;

  &:nth-child(2) {
    padding-top: 12px;
  }

  .items-heading {
    display: block;
    @include fs-16;
    font-weight: $font-medium;
  }

  .collapse-heading {
    .icon {
      font-size: 24px;
    }
  }

  .collapse-open {

    &:hover,
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
      text-decoration: none;
    }
  }
}

.filter-items {
  margin-top: 15px;
  max-height: 248px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;

  @-moz-document url-prefix() {
    max-height: 245px;
  }

  @include media(down, $breakpoint-xxl) {
    @-moz-document url-prefix() {
      max-height: 266px;
    }

    max-height: 268px;
  }

  @include media(down, $breakpoint-md) {
    max-height: 250px;
  }

  &.collapse-item {
    height: 0;
    opacity: 0;
    margin: 0;
  }

  &.show {
    // @include css3-prefix(transition, all .4s ease-in-out);
    height: auto;
    opacity: 1;
    margin-top: 15px;
  }

  .form-check {
    // padding-left: 40px;
    margin-bottom: 14px;

    .form-check-label {
      // display: inline-block;
      // margin: 0;
      @include fs-16;
      line-height: 1.4;
      text-transform: uppercase;
      @include media(down, $breakpoint-md) {
        line-height: 1.9;
      }
      @-moz-document url-prefix() {
        line-height: 1.5;
      }

      cursor: pointer;
    }
  }
}

.view-catalog {
  .title-content {
    flex: 0 0 342px;
    max-width: 342px;

    @include media(down, $breakpoint-xxl) {
      flex: 0 0 298px;
      max-width: 298px;
    }
    @include media(down, $breakpoint-xl) {
      flex: 0 0 360px;
      max-width: 360px;
    }

    @include media(down, $breakpoint-lg) {
      flex: 0 0 100%;
      max-width: 100%;
    }

    // @include media(down, $breakpoint-sm) {
    //   flex: 0 0 75%;
    //   max-width: 75%;
    // }
  }
}

.search-bar {
  flex: 1 1 auto;
  margin-left: 5px;

  @include media(down, $breakpoint-lg) {
    margin: 0;
    justify-content: end;
  }

  .form-group {
    flex: 0 0 300px;
    margin-left: auto;

    @include media(down, $breakpoint-lg) {
      flex: 0 0 200px;
      margin: 1rem 0 0;
    }

    @include media(down, $breakpoint-md) {
      flex: 0 0 100%;
      margin: 1rem 0 !important;
      width: 100%;
    }
  }

  .btn {
    flex: 0 0 150px;
    padding: 8px 10px;
    margin-left: 20px;
    font-size: 15px;
    font-weight: $font-bold;
  }

  .field-icon {
    .form-control {
      // height: 42px;
      font-size: 16px;
      line-height: 26px;
      padding-right: 26px;

      @include media(down, $breakpoint-xxl) {
        font-size: 12px;
        line-height: 22px;
      }
    }

    .icon {
      &::before {
        color: $secondary;
      }
    }
  }
}

.img-hover-zoom {
  transition: transform .25s, visibility .25s ease-in;
}

/* The Img zoom Transformation */
.img-hover-zoom:hover {
  transform: scale(1.3);
}

.fluid {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  font-family: Arial;
  line-height: 1.3;
  font-size: 16px;
}

a:hover {
  color: #666;
}

.code {
  font-family: Courier New, Courier, monospace;
}

@media (min-width: 480px) {
  .fluid {
    flex-direction: row;
  }

  .fluid__image-container{
    flex: 0 0 30%;
    margin: 20px 0 20px 20px;
  }

  .fluid__instructions {
    flex: 0 0 50%;
    padding-top: 30px;
  }

  .fixed__instructions {
    padding-top: 30px;
    margin: 0 10px;
  }

  .portal {
    position: absolute;
    top: 40px;
    left: -30px;
  }
}