.faq-content {
  .tab-left {
    flex: 0 0 540px;
    max-width: 540px;

    @include media(down, $breakpoint-xxl) {
      flex: 0 0 516px;
      max-width: 516px;
    }

    @include media(down, $breakpoint-xl) {
      flex: 0 0 400px;
      max-width: 400px;
    }

    @include media(down, $breakpoint-lg) {
      flex: 0 0 300px;
      max-width: 300px;
    }

    @include media(down, $breakpoint-md) {
      flex: 0 0 100%;
      max-width: 100%;
      overflow-x: visible;
      // scroll-snap-type: x mandatory;
    }
  }

  .nav-pills {
    border: 1px solid $border-color;
    border-radius: 4px;

    @include media(down, $breakpoint-md) {
      border: 0;

    }

    .nav-item {
      padding: 0;

      @include media(down, $breakpoint-md) {
        min-width: 350px;
        border: 1px solid $border-color;
        border-radius: 0;
        // scroll-snap-align: center;
      }
      &:not(:first-child) {
        border-top: 0;
      }
      &:not(:last-child) {
        a {
          &::before {
            content: "";
            position: absolute;
            top: auto;
            bottom: 0;
            left: 0;
            right: 0;
            width: 92%;
            height: 1px;
            margin: 0 auto;
            background-color: $border-color;

            @include media(down, $breakpoint-lg) {
              height: 0;
            }
          }
        }
      }

      a {
        position: relative;
        display: flex;
        padding: 24px 40px 24px 24px;

        @include media(down, $breakpoint-xl) {
          padding: 24px;
        }

        color: $dark;
        @include fs-16;
        border-radius: 0;
        min-height: 65px;
        align-items: center;

        @include media(down, $breakpoint-md) {
          padding: 14px 32px 14px 14px;
          line-height: 1.5;
        }

        &.active {
          background-color: $border-color;
          color: #F17834;
          font-weight: $font-bold;
          min-height: 65px;
          align-items: center;
        }

        .icon {
          position: absolute;
          top: 50%;
          left: auto;
          right: 20px;
          font-size: 20px;
          @include css3-prefix(transform, translateY(-50%));

          @include media(down, $breakpoint-xl) {
            right: 6px;
          }

          @include media(down, $breakpoint-md) {
            right: 10px;
          }

          @include media(down, $breakpoint-md) {
            transform: rotateZ(90deg);
            margin-top: -8px;
          }
        }

        &[aria-selected="true"] {
          .icon {
            @include media(down, $breakpoint-md) {
              transition: 0.5s ease-in-out;
              transform: rotateZ(268deg);
            }
          }
        }
      }
    }
  }

  .tab-right {
    width: 100%;
    margin-left: 30px;

    @include media(down, $breakpoint-xl) {
      margin-left: 20px;
    }

    @include media(down, $breakpoint-md) {
      margin-left: 0;
    }
  }

  .tab-pane {
    padding: 20px;
    border: 1px solid $border-color;
    border-radius: 4px;
  }

  .mobile-tab-content {
    @include media(down, $breakpoint-md) {
      padding: 10px;

      p {
        &:first-child {
          display: none;
        }
      }
    }
  }
}

.tab-content {
  h3 {
    color: $dark;
    @include fs-18;
  }

  p {
    margin-bottom: 20px;
    text-align: justify;
    @include fs-16;
  }

  ul {
    padding-left: 0;
    list-style: none;
    @include fs-16;

    li {
      position: relative;
      padding-left: 15px;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 10px;
        height: 5px;
        width: 5px;
        background-color: $dark;
        border-radius: 100%;
      }

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
}