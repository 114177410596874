.underline-link {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    color: $secondary;
  }
}

.trash-btn {

  &:hover {
    background-color: rgba($danger, .7);
    box-shadow: none;

    .icon {
      color: $white;
    }
  }
}

.clear-btn {
  color: $dark-blue;

  @include media(down, $breakpoint-sm) {
    white-space: nowrap;
  }

  &:hover {
    color: $secondary;
  }
}

.update-btn {
  color: $primary;
  @include fs-16;

  &:hover,
  &:focus {
    color: $secondary;
  }
}

/* ******** */

.product-des {
  .product-title {
    margin-bottom: 4px;
    @include fs-20;
  }

  .eqi-value {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .btn-link {
    @include fs-14;
    color: $secondary;

    &:hover,
    &:focus,
    &active {
      color: $secondary;
      box-shadow: none;
      text-decoration: underline;
    }
  }
}

.cart-items-titles {
  margin-bottom: 12px;

  h6 {
    margin-bottom: 0;
    @include fs-16;
    font-weight: $font-bold;
  }

  .items {
    flex: 0 0 500px;
  }
}

// Cart content
.product-cart-list {
  flex: 1 1 auto;
  max-width: inherit;

  @include media(down, $breakpoint-lg) {
    flex: 1 1 60%;
    max-width: 60%;
  }

  @include media(down, $breakpoint-md) {
    flex: 1 1 100%;
    width: 100%;
    max-width: inherit;
  }

  // Card Structure
  .card-list-title {
    li {
      flex: 1 1 134px;
      padding: 10px 0;
      @include fs-16;
      font-weight: $font-bold;
      vertical-align: middle;

      &:not(:first-child) {
        text-align: center;
      }

      &:first-child {
        flex: 0 0 595px;

        @include media(down, $breakpoint-xxl) {
          flex: 0 0 465px;
        }

        @include media(down, $breakpoint-lg) {
          flex: 0 0 465px;
        }
      }

      &:last-child {
        margin-right: 56px;
      }
    }
  }

  .card {
    border: 1px solid $light;
    border-radius: 5px;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    .price-info {
      flex: 1 1 auto;

      @include media(down, $breakpoint-sm) {
        align-items: flex-start;
      }
    }

    .trash-btn {
      @include media(down, $breakpoint-xl) {
        position: absolute;
        top: 12px;
        right: 12px;
        left: auto;

      }

      @include media(down, $breakpoint-xl) {
        top: auto;
        bottom: 10px;
        height: 34px;
        width: 34px;
      }
    }

    .btn-link {
      @include media(down, $breakpoint-md) {
        font-size: 14px;
        margin-top: 5px;
      }
    }

    &.may-like-section {
      margin-top: 7px;

      .card-body {
        margin-top: 0;
      }

      .catalog-list {
        margin-left: inherit;
        margin-top: 8px;

        &.may-like-scroll {
          max-height: 469px;
          overflow-y: auto;
          overflow-x: hidden;

          .col-xl-4 {
            padding: 0 8px;
            .card{
            @extend .px-6-1366;
            .eqi-place{
              .lable-n-value:first-child{
                @extend .flex-wrap-1366;
              }
            }
            }
          }
        }

        .inner-wrap {
          &.row {
            margin-right: -8px;
            margin-left: -8px;

            [class^="col-"] {
              padding-left: 8px;
              padding-right: 8px;
            }
          }
        }

      }
    }
  }

  .card-body {
    // display: flex;
    padding: 16px;

    .product-img {
      flex: 0 0 auto;
    }
  }

  .tab-title {
    font-size: 14px;
  }

  .card-product-detail {
    flex: 1 1 auto;

    .cart-product {
      flex: 0 0 450px;

      @include media(down, $breakpoint-xxl) {
        flex: 0 0 330px;
      }

      @include media(down, $breakpoint-lg) {
        flex: 0 0 320px;
      }

      @include media(down, $breakpoint-xl) {
        flex: 0 0 auto;
        // justify-content: center;
      }

      @include media(down, $breakpoint-md) {
        flex: 0 0 auto;
        justify-content: start;
      }
    }

    .unit-details {
      @include media(down, $breakpoint-xl) {
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        padding: 4px 0;
      }

      @include media(down, $breakpoint-md) {
        border-top: unset;
        border-bottom: unset;
        padding: 0;
      }

      span {
        @include media(down, $breakpoint-xl) {
          display: block;
          font-weight: $font-bold;
        }

        @include media(down, $breakpoint-md) {
          display: unset;
          font-weight: inherit;
        }
      }
    }

    .price-info {
      .item-cost {
        @include media(down, $breakpoint-lg) {
          display: block;

          .item-price {
            display: block;
          }
        }

        @include media(down, $breakpoint-md) {
          display: flex;

          .item-price {
            display: flex;
          }
        }
      }

      &.shopping-qty {
        form {
          @include media(down, $breakpoint-lg) {
            display: inline-block;
          }
        }
      }
    }

    .item-cost {
      flex: 1 1 134px;
      text-align: center;

      @include media(down, $breakpoint-xl) {
        display: flex;
        flex: 0 0 auto;
        justify-content: center;
        align-items: center;
        text-align: left;
      }
    }
  }

  .item-price {
    line-height: 28px;
  }

  // Table Structure
  .cart-product {
    flex: 0 0 532px;

    .product-img {
      flex: 0 0 105px;
      display: flex;
      justify-content: center;

      img {
        max-width: 112px;
        max-height: 112px;
      }
    }
  }

  .number-select {
    margin-top: 0 !important;

    .custom_select__control {
      margin: 0 auto;
    }
  }
}

.bill-summary {
  position: sticky;
  top: 130px;
  flex: 0 0 450px;
  margin: 48px 0 0 30px;
  max-width: 450px;

  @include media(down, $breakpoint-xxl) {
    flex: 0 0 420px;
    margin-top: 47px;
  }

  @include media(down, $breakpoint-xl) {
    margin: 0 0 0 15px;
    flex: 1 1 40%;
    width: 40%;
  }

  @include media(down, $breakpoint-md) {
    flex: 1 1 100%;
    width: 100%;
    margin: 30px 0 0 0;
    max-width: inherit;
  }


  .card {
    border: 1px solid $light;
    border-radius: 5px;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    .card-body {
      padding: 12px;

      h4 {
        line-height: 28px;
      }
    }
  }
}

.summary-list {
  margin-top: 20px;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;

    @include media(down, $breakpoint-xxl) {
      padding-bottom: 20px;
    }

    &:not(:first-child) {
      padding-top: 12px;

      @include media(down, $breakpoint-xxl) {
        padding-top: 20px;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }

    span {
      @include fs-16;
    }
  }
}

// Empty cart
.empty-cart {
  .empty-content {
    margin-top: 30px;
  }

  .empty-cart-heading {
    margin-bottom: 10px;
    @include fs-20;
    font-weight: $font-bold;
  }

  p {
    @include fs-16;
  }

  .btn {
    margin-top: 20px;
    min-width: 150px;
  }
}

// .unit-details {
//   .eqi-value {
//     @include media(down, $breakpoint-lg) {
//       display: flex;
//       flex-direction: column;
//     }
//   }
// }


.shopping-cart {
  .title-content {
    @include media(down, $breakpoint-lg) {
      flex: 0 0 auto;
      max-width: inherit;
    }
  }
}

.review-order-wrapper {
  .proceed-order-details {
    border: 1px solid #ECEEF3;
    border-radius: 4px;
    padding: 20px;

    .product-cart-list {
      border-bottom: 1px solid #ECEEF3;
      border-radius: 0;

      &:last-child {
        border-bottom: 0;
      }

      &:first-child {
        .card {
          padding-top: 0;
        }
      }

      &:last-child {
        .card {
          padding-bottom: 0;
        }
      }

      .card {
        border: 0;
        padding: 1.25em 0;
      }
    }

    .product-des {
      .product-title {
        @include fs-18;
        line-height: 1.375em;
      }
    }
  }

  .bill-summary {
    margin: 0 0 0 30px;


    @include media(down, $breakpoint-md) {
      flex: 1 1 100%;
      width: 100%;
      margin: 0px 0 0 0;
      max-width: inherit;
    }

  }

  .ship-n-bill {
    margin: 0 auto;

    .ship-n-bill-cards {
      @include media(down, $breakpoint-md) {
        padding-left: 0;
        padding-right: 0;
        margin: 0 auto 1rem auto;

      }

      .card {
        border: 1px solid #ECEEF3;

        .contact-no {
          color: #226BB3;
        }

        .card-header {
          background-color: #ECEEF3;
        }

        .card-body {
          padding: 12px 20px;

          p {
            line-height: 22px;
          }
        }
      }
    }
  }

  .order-detail-preview {
    .card-header {
      h4 {
        @include fs-18;
      }
    }

    .icon-chevron-down {
      &.active {
        transform: rotate(180deg) !important;
      }
    }

    .specification-list {
      .product-cart-list {

        .card {
          border-radius: 0;
          border-top: 1px solid #ECEEF3 !important;
        }
      }
    }

    .product-des {
      .product-title {
        @include fs-16;
        line-height: 22px !important;
      }

      .item-cost {
        @include fs-14;

        @include media(down, $breakpoint-xl) {
          justify-content: start;
        }

        .item-price {
          line-height: 18px !important;
        }
      }
    }

    .order-detail-scroll {
      height: auto;
      // max-height: 488px;
      overflow: auto;
    }
  }
}