.dashboard-content {
  .row-box-space {
    flex: 1 1 950px;

    @include media(down, $breakpoint-tab) {
      flex: 1 1 100%;
    }

    @include media(down, $breakpoint-md) {
      justify-content: space-between;
      margin: 0;
      width: 100%;
    }

    .box-img {
      position: relative;
      padding: 10px;
      background-size: cover;
      background-repeat: no-repeat;
      min-height: 235px;
      background-size: contain;

      @include media(down, $breakpoint-md) {
        margin: 0 0 20px 0;
      }

      &::after {
        content: "";
        position: absolute;
        top: auto;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        // background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 66.91%);
      }
    }

    .box-item {
      width: calc(100%/3 - 45px);
      margin: 0 20px 30px;
      background-color: #f2f2f2;
      @include media(down, $breakpoint-lg) {
        margin: 0 10px 15px;
      }

      @include media(down, $breakpoint-md) {
        width: calc(50% - 2%);
        margin: 0 0 20px 0;
      }

      @include media(down, $breakpoint-sm) {
        width: 100%;
      }
    }
  }

  .title-content {
    position: relative;
    z-index: 1;

    h2 {
      font-size: 15px;
      line-height: 22px;
    }

    .icon {
      color: $dark;
    }
  }
}

.epop-news {
  flex: 0 0 365px;
  margin-left: 30px;
  padding: 12px;
  border: 1px solid $border-color;
  border-radius: 4px;

  @include media(down, $breakpoint-xxl) {
    flex: 0 0 298px;
  }

  @include media(down, $breakpoint-lg) {
    flex: 1 1 200px;
    margin: 0;
  }

  @include media(down, $breakpoint-md) {
    flex: 1 1 100%;
    width: 100%;
    margin: 20px 0 0;
  }
}

.news-list {
  overflow: auto;
  // max-height: 492px;
  max-height: 526px;
  margin-top: 12px;
  padding-right: 12px;

  @include media(down, $breakpoint-tab) {
    width: 100%;
  }

  @include media(down, $breakpoint-lg) {
    width: 200px;
  }

  @include media(down, $breakpoint-md) {
    width: 100%;
  }

  li {
    &:not(:last-child) {
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid $border-color;
    }
  }

  .news-heading {
    h5 {
      @include media(down, $breakpoint-xxl) {
        max-width: 230px;
      }

      max-width: 294px;
      @include fs-16;
      line-height: 1.4;
      font-weight: $font-bold;
    }

    .icon {
      color: $primary;
      font-size: 16px;
    }
  }

  .news-time {
    color: #878991;
    @include fs-14;
    min-height: inherit;
  }

  .news-link-dashborad {
    @include media(down, $breakpoint-xxl) {
      max-width: 230px;
    }

    max-width: 294px;
    @include fs-16;
    font-weight: $font-regular;

    .icon {
      &::before {
        @include fs-18;
        font-weight: $font-bold;
      }
    }
  }

  .news-para {
    margin-top: 10px;

    p,
    span {
      margin-bottom: 0;
      @include fs-14;
      text-align: justify;

      @include media(down, $breakpoint-xl) {
        text-align: left;
      }
    }

    span {
      color: $warning;
    }

    .read-more {
      color: $secondary;
      @include fs-16;
      text-decoration: underline;
    }
  }
}

.no-record {

  @include media(down, $breakpoint-lg) {
    h6 {
      @include fs-18
    }
    p {
      @include fs-14
    }
  }

  @include media(down, $breakpoint-md) {
    h6 {
      @include fs-20
    }
    p {
      @include fs-18
    }
  }
}