/*Headings*/
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	font-weight: $font-medium;
	font-family: $font-heading;
}
// h1, .h1 { font-size: $font-size-base * 2.5;}
// h2, .h2 { font-size: $font-size-base * 2;}
// h3, .h3 { font-size: $font-size-base * 1.75;}
// h4, .h4 { font-size: $font-size-base * 1.5;}
// h5, .h5 { font-size: $font-size-base * 1.25;}
// h6, .h6 { font-size: $font-size-base; }

// HEADING TYPOGRAPHY
$heading-size: (size(80), size(40), size(32), size(24), size(22), size(20));

@for $i from 1 through 6 {
	h#{$i}, .h#{$i} {
		font-size: nth($heading-size, $i);
	}
}
