/**
* ----------------------------------------
* Header section
* ----------------------------------------
*/
.header {
  z-index: 99;
  position: sticky;
  top: 0;
  background-color: $white;
  box-shadow: 0px 4px 8px rgba($black, 0.22);
}

.navbar {
  padding: 0;

  .container {
    padding: 0 15px;
  }

  .navbar-nav {
    .nav-item {
      position: relative;
      padding: size(20) 0;
      margin-right: 24px;
      // &:not(:last-child) {
      //   margin-right: 24px;

      //   @include media(down, $breakpoint-xxl) {
      //     margin-right: 0;
      //   }
      // }
      @include media(up, $breakpoint-xl) {

        &:hover,
        &:focus {
          .megamenu {
            opacity: 1;
            z-index: 399;
            width: 274px;
            pointer-events: auto;
            visibility: visible;
            margin-top: 0;
            // @include css3-prefix(transform, translateX(0));  
          }

          .nav-link {
            &::after {
              transform: scale(1) translateX(-50%);
            }
          }
        }
      }

      .megamenu {
        @include media(down, $breakpoint-xl) {
          margin-top: 0;
          display: none;
          &.active {
            opacity: 1;
            visibility: visible;
            pointer-events: painted;
            width: auto;
            min-width: 140px;
            display: block;
          }
        }
      }

      &.active {
        .nav-link {
          &::after {
            transform: scale(1) translateX(-50%);
          }
        }
      }
    }

    .nav-link {
      padding: 17px 17px;
      color: $dark;
      position: relative;
      @extend .font-18;
      font-weight: $font-medium;
      // line-height: 30px;
      letter-spacing: -0.25px;
      cursor: pointer;

      &:hover {
        color: $dark;
      }

      @include media(up, $breakpoint-xl) {
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          right: 0;
          width: 86%;
          height: 3px;
          background-color: $primary;
          transition: opacity 300ms, transform 300ms;
          opacity: 1;
          transform: scale(0) translateX(-50%);
          transform-origin: center;
          z-index: 999;
        }
      }


      @include media(down, $breakpoint-xl) {
        padding: 15px 10px;
      }

      .icon-chevron-down {
        font-size: 18px;
        margin-left: 7px;
      }

      .icon-user {
        font-size: 24px;
      }

      &.user-dropdown {
        .icon-chevron-down {
          font-size: 22px;
        }
      }
    }
  }

  .navbar-brand {
    flex: 0 0 auto;

    img {
      @include media(down, $breakpoint-lg) {
        max-width: 80%;
      }
      height: 30px;
    }
  }

  .main-menu {
    @include media(up, $breakpoint-lg) {
      width: 100%;
    }

    @include media(down, $breakpoint-lg) {
      z-index: 9999;
      position: fixed;
      top: 0;
      left: -100%;
      height: 100vh;
      width: 260px;
      padding: 40px 15px 15px;
      background-color: $white;
      align-items: flex-start;
      @include css3-prefix(transition, all 0.2s ease-in-out);

      &.active {
        left: 0;
      }
    }

    .navbar-nav {
      @include media(down, $breakpoint-lg) {
        flex-direction: column;
      }
    }

    .nav-link {
      @include media(down, $breakpoint-lg) {
        justify-content: space-between;
        padding: 0;
      }
    }

    .megamenu {
      
      @include media(down, $breakpoint-lg) {
        display: none;
        position: relative;
        padding: 0;
        margin: 0;
        border: none;
        box-shadow: none;
        left: 0;
        margin-top: 10px !important;

        &.active {
          opacity: 1;
          visibility: visible;
          pointer-events: painted;
          width: auto;
          display: block;
        }
      }
    }

    .nav-item {
      @include media(down, $breakpoint-lg) {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.overlay-bg {
  z-index: 1024;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba($black, 0.5);
  width: 100%;
  height: 100%;
  display: none;
  @include css3-prefix(transition, all 0.4s ease-in-out);

  &.active {
    display: block;
  }
}

.menu-close {
  position: absolute;
  top: 10px;
  left: auto;
  right: 10px;
  justify-content: center;
  align-items: center;
  height: 26px;
  width: 26px;
  background: $light;
  border-radius: 50px;
  padding: 8px;
  font-size: 10px;
}

.right-nav {
  .icon-cart {
    font-size: 24px;
    vertical-align: middle;
  }

  .megamenu {
    left: auto;
    right: 0;

    @include media(down, $breakpoint-lg) {
      &.active {
        opacity: 1;
        visibility: visible;
        pointer-events: painted;
        width: auto;
        display: block;
      }
    }


  }

  .nav-link {
    &::after {
      display: none;
    }
  }

  .right-nav-link {
    &::after {
      display: block;
    }
  }

}

.cart-view {
  .cart-items {
    position: absolute;
    top: 0;
    left: auto;
    right: -7px;
    height: 16px;
    width: 16px;
    background-color: $warning;
    color: $white;
    font-size: 11px;
    line-height: 16px;
    border-radius: 100%;
    font-weight: $font-bold;

    &:empty {
      opacity: 0;
    }
  }
}

/**
* ----------------------------------------
* MEGA-MENU
* ----------------------------------------
*/
.megamenu {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  width: auto;
  min-width: 260px;
  margin-top: size(15);
  padding: size(15);
  background: $white;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  box-shadow: 0px 4px 30px rgba($black, 0.08);

  // transition: all 0.4s ease, margin 0.25s ease;
  @include media(down, $breakpoint-xl) {
    min-width: 260px;
  }
  @include media(down, $breakpoint-lg) {
    min-width: inherit;
  }
  @include media(down, $breakpoint-md) {
    min-width: inherit;
  }
  .menu-list {
    list-style: none;

    li {
      padding: 10px 0;

      @include media(down, $breakpoint-lg) {
        padding: 8px 0;
      }

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }

      a {
        display: flex;
        align-items: center;
        color: $dark;
        @include fs-14;
        font-weight: $font-medium;
        white-space: nowrap;

        &:hover,
        &:active,
        &:focus {
          color: $secondary;
          // @include css3-prefix(transform, translateX(5px));
        }

        &.active {
          color: $primary;
        }

        .icon {
          font-size: 20px;
          margin-right: 15px;

          @include media(down, $breakpoint-lg) {
            font-size: 16px;
          }
        }
      }
    }
  }
}