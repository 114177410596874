// MEDIA BREAKPOINTS
@mixin media($breakpoint, $point) {
  @if $breakpoint == down {
    @media (max-width: ($point - .1)) { @content; }
  }
  @else if $breakpoint == up {
    @media (min-width: $point) { @content; }
  }
}
// How to use media mixin demo
//  body {
//    background:$primary;
//    @include media(down, $breakpoint-xl) {
//      background: $secondary;
//    }
//  }
//  body {
//    background: $secondary;
//    @include media(up, $breakpoint-xl) {
//      background: $primary;
//    }
// }

// CALC FUNCTION RULE
@mixin calc($property, $expression) { 
  #{$property}: calc(#{$expression});
}
// Usage Calc Function demo 
// div {
//    @include calc( width, '100% - 2px');
// }

// PREFIX RULE 
@mixin css3-prefix($prop, $value) {
    -webkit-#{$prop}: #{$value};
    -moz-#{$prop}: #{$value};
    -ms-#{$prop}: #{$value};
    -o-#{$prop}: #{$value};
    #{$prop}: #{$value};
}
// Usage Prefix 
// div {
//    @include css3-prefix(transform, scale3d(2.5, 2, 1.5));
// }
  
// POSITION 
@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

// TEXT ELLIPSIS
@mixin text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// BORDER RADIUS 
@mixin border-radius($border-radius) {
  border-radius: $border-radius;
  -webkit-border-radius: $border-radius;
  -moz-border-radius: $border-radius;
}

// Button sizes
@mixin button-size($btn-padding-y, $btn-padding-x, $font-size-base, $line-height-base, $box-shadow) {
  padding: $btn-padding-y $btn-padding-x;
  font-size: $font-size-base;
  line-height:$line-height-base;
  box-shadow: $box-shadow;
}


// Font's size 
// @mixin font-size($font-size, $line-height: normal) {
//   font-size: $font-size * 1px;
//   // font-size: $font-size * 0.1rem;
//   // example using rem values and 62.5% font-size so 1rem = 10px

//   @if $line-height==normal {
//     line-height: normal;
//   } @else {
//     line-height: $line-height / $font-size;
//   }
// }

// TEXT ARGUMENT
@mixin text($font-size, $color, $line-height, $font-weight) {
  // font-size: $font-size * 1rem;
  // If the $font-size argument is not empty, then output the argument
  @if $font-size != null {
    font-size: $font-size;
  }
  // If the $color argument is not empty, then output the argument
  @if $color != null {
    color: $color;
  }
  
  // If the $line-height argument is not empty, then output the argument
  @if $line-height != null {
    line-height: $line-height;
  }
  
  // If the $font-weight argument is not empty, then output the argument
  @if $font-weight != null {
    font-weight: $font-weight;
  }
}


// Font Size
@mixin font-size($font-size, $line-height: normal) {
  font-size: $font-size * 1px;
  // font-size: $font-size * 0.1rem;
  // example using rem values and 62.5% font-size so 1rem = 10px

  @if $line-height==normal {
    line-height: normal;
  } @else {
    line-height: $line-height / $font-size;
  }
}

// How to use
// @include font-size(12, 18);


@mixin fs-24 {
  @include font-size(25, 24);
	@include media(down, $breakpoint-xxl) {
    @include font-size(22, 32);
	}
  @include media(down, $breakpoint-sm) {
    @include font-size(20, 30);
	}
}

@mixin fs-20 {
  @include font-size(20, 26);
	@include media(down, $breakpoint-xxl) {
    @include font-size(18, 24);
	}
  @include media(down, $breakpoint-sm) {
    @include font-size(16, 22);
	}
}
@mixin fs-18 {
  @include font-size(18, 30);
	@include media(down, $breakpoint-xxl) {
    @include font-size(16, 28);
	}
  @include media(down, $breakpoint-sm) {
    @include font-size(14, 26);
	}
}

@mixin fs-16 {
  @include font-size(16, 28);
	@include media(down, $breakpoint-xxl) {
    @include font-size(14, 26);
	}
  @include media(down, $breakpoint-sm) {
    @include font-size(12, 18);
	}
}

@mixin fs-14 {
  @include font-size(14, 18);
	@include media(down, $breakpoint-xxl) {
    @include font-size(12, 16);
	}
}



// PLACEHOLDER RULE
@mixin placeholder {
  &::-webkit-input-placeholder {@content};
  &:-moz-placeholder           {@content};
  &::-moz-placeholder          {@content};
  &:-ms-input-placeholder      {@content};
}

// Gradients
@mixin x-linear-gradient($direction, $fallback, $from, $to) {
  $directions: helper-gradient-angle($direction);
  // Provide a fallback-color
  background-color: $fallback;
  // Cross-browser linear-gradients
  background-image: -webkit-gradient(linear,$directions, from($from), to($to)); // Android 2.1-3.0
  background-image: -webkit-linear-gradient($directions, $from, $to);
  background-image:linear-gradient($direction, $from, $to);
}