.nav-slider {
  &.slick-slider {
    @include media(down, $breakpoint-lg) {
      max-width: 70%;
      margin: 0 auto;
    }

    @include media(down, $breakpoint-xs) {
      max-width: 100%;
    }
  }
}

.product-slider {
  &.slick-slider {
    @include media(down, $breakpoint-lg) {
      max-width: 70%;
      margin: 0 auto 10px;
    }

    @include media(down, $breakpoint-xs) {
      max-width: 100%;
    }
    .slick-list{
      overflow: visible;
      z-index: 999;
    }
  }

  margin-bottom: 10px;
  border: 1px solid $light;
  border-radius: 5px;

  .slider-items {
    padding: 30px;
    vertical-align: top;
  }
  .slider-items img{
    max-width: none !important;
    background-color: #fff !important;
  }
  .slider-items img[alt='product-img']{
    width:auto !important;
    max-width:200px !important;
    height:auto !important;
  }
}

.nav-slider {
  @include media(down, $breakpoint-sm) {
    // display: none;
  }

  .slick-arrow {
    display: none !important;
  }

  .slick-slide {
    padding: 0 2.5px;

    &.slick-current {
      .slider-items {
        border-color: #06237D;
      }
    }
  }

  .slider-items {
    padding: 10px 18px;
    border: 1px solid $light;
    border-radius: 5px;
    vertical-align: top;
  }
}

// Slick Arrow
.slick-arrow {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 15px;
  height: 24px;
  width: 24px;
  border-radius: 50px;
  background-color: $light;
  transition: $transition-base;
  @include css3-prefix(transform, translateY(-50%));

  &:hover {
    background-color: $gray-400;

    &::before {
      color: $black;
    }
  }

  &::before {
    content: "\e912";
    opacity: 1;
    font-size: 14px;
    color: #08287F;
    font-family: $icomoon-font-family;
  }

  &.slick-next {
    right: 15px;
    left: auto;

    &::before {
      content: "\e911";
    }
  }
}