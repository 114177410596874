.row-box-space {
    margin: 0 -15px;

    .box-item {
        width: calc(100%/3 - 30px);
        padding: 30px;
        margin: 0 15px 30px;
        border-radius: 4px;
        background-color: rgba(34, 108, 181, 0.1);
        overflow: hidden;

        @include media(down, $breakpoint-lg) {
            width: calc(100%/2 - 30px);
        }

        @include media(down, $breakpoint-sm) {
            width: 100%;
            padding: 15px;
            margin: 0 15px 15px;
        }
    }

    .box-title {
        margin: 15px 0 5px;
        line-height: 28px;
    }

    .box-content {

        p,
        a {
            margin-bottom: 0;
            @include fs-16;

            @include media(down, $breakpoint-lg) {
                text-overflow: ellipsis;
                overflow: hidden;
                display: block;
            }
        }

        span {
            color: $dark-blue;
        }
    }
}