/** ---------------------------------------- CUSTOM SELECT ---------------------------------------- **/
// react responsive multiselect

.custom-multiselect {
    &.react-multi-select {
        .custom_select__multi-value {
            line-height: 1.8;
            padding: 4px 11px;
        }
    }
}