.catalog-wrap {
  .order-info {
    .card {
      .card-header {
        border-radius: 4px;
        background: $border-color;
        @include fs-16;
        font-weight: $font-semibold;
      }

      .card-body {
        p {
          @include fs-16;
          font-weight: $font-regular;
        }
      }
    }
  }

  table {
    td {
      a {
        display: block;
        .icon-eye-on {
          line-height: 0.8;
        }
      }
    }
  }

  .list-group-flush {
    font-size: $font-size-sm;
  }

  .filter-bar {
    .custom_select__control {
      padding: 0 20px;
      @extend.sm-single-select;
    }
  }
}

.view-order {
  +.catalog-section {
    .filter-bar {
      @include media(down, $breakpoint-lg) {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 1.5rem;
        width: 260px;
      }
    }
  }

  .view-table-action {
    &.search-bar {
    @include media(down, $breakpoint-xl) {
      flex: 1 1 220px;
    margin-left: 30px;
    }
    @include media(down, $breakpoint-lg) {
      flex: 1 1 100%;
      margin-left: 0;
    }
      .btn {
        @include media(down, $breakpoint-xl) {
          padding: 5px 10px;
        }

        @include media(down, $breakpoint-md) {
          flex: 0 0 auto;
          width: 80px;
        }
      }

      // flex: inherit;
      .form-group {
        @include media(down, $breakpoint-xl) {
          flex: 0 0 220px;
          margin-left: auto;
        }
        @include media(down, $breakpoint-lg) {
          flex: 1 1 100%;
          margin-left: 0;
        }
        &.order-billto-select {
          @include media(down, $breakpoint-md) {
            margin: 0 !important;
          }
        }

        &:not(:first-child) {
          margin: 0 0 0 20px;

          @include media(down, $breakpoint-sm) {
            margin-left: 0;
          }
        }
        &.field-icon-230flx{
          flex: 0 0 228px;
        }
      }

      label {
        margin-right: 8px;
        white-space: nowrap;
      }

      .field-icon {
        width: 300px;

        @include media(down, $breakpoint-xl) {
          width: 100%;
        }

        &.field-icon-space{
          width: 275px;
        }
      }

      .react-select {
        width: 100%;
        min-width: 300px;

        @include media(down, $breakpoint-xl) {
          min-width: 220px;
        }

        .custom_select__control {
          @extend .sm-single-select;
          // padding: 0 24px 0 12px;
        }
        &.field-icon-dropdown{
          min-width: 215px;
        }
      }
    }
  }
}

.order-details-grid {
  &.custom-table {
    tr.view-order-grid {
      &:hover {
        background-color: transparent;
      }

      td {
        border-top: 1px solid $border-color;
      }

      &.last-tr-border {
        border-bottom: 1px solid $border-color;

        td {
          border-top: 0;
        }

        &:hover {
          background-color: transparent;
        }
      }
    }
    tr.col-no-border {
    white-space: pre;
    }
  }
}

.order-details-layout {
  .card-body {
    margin-top: 12px;
  }
}

.view-order-details {
  .search-bar {
    .btn {
      @include media(down, $breakpoint-md) {
        flex: 0 0 auto;
        width: 100px;
      }
    }
  }
}