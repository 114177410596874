.my-account-content {
  .card {
    &:not(:last-child) {
      margin-bottom: 25px;
    }

    h4 {
      line-height: 28px;
    }

  }

  .selected-item {
    max-width: 100%;
  }

  .address-select {
    margin-top: 20px;
  }

  .address-search {
    max-width: 100%;
    min-width: auto;
  }

  .static-address {
    display: block;
    margin-top: 20px;
    @include fs-18;

    @include media(down, $breakpoint-sm) {
      margin-top: 10px;
    }
  }

  .filter-items {
    max-height: 266px;

    @include media(down, $breakpoint-xl) {
      max-height: inherit;
      overflow: visible;
    }

    // @include media(down, $breakpoint-lg) {
    //   max-height: 270px;
    // }

    @include media(down, $breakpoint-md) {
      max-height: inherit;
      overflow: visible;
    }

    .form-check {
      .form-check-label {
        @include fs-18;
        text-transform: uppercase;
      }
    }

    li {
      @include fs-18;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    .custom-radio {
      span {
        top: 2px !important;
      }

      label {
        padding: 0 0 0 32px;
      }
    }
  }
}

.my-program-list {
  max-height: 311px;

  @include media(down, $breakpoint-xl) {
    max-height: inherit;
    overflow: visible;
  }

  overflow: auto;
  position: relative;

  li {

    line-height: normal;
  }
}

.user-details {
  margin-top: 20px;

  @include media(down, $breakpoint-sm) {
    margin-top: 5px;
  }

  .user-info {
    &:not(:last-child) {
      padding-right: 50px;
      flex: 0 0 170px;

      @include media(down, $breakpoint-sm) {
        flex: 0 0 auto;
      }
    }

    span {
      line-height: 30px;
      color: $gray;
    }

    p {
      line-height: 1.6;
    }
  }
}