// .address-select {
//   margin-top: 10px;
//   font-size: 13px;
//   line-height: 20px;
//   cursor: pointer;

//   // .selected-item {
//   //   max-width: 272px;
//   // }
//   .selected-value {
//     max-width: 100%;
//   }

//   .icon {
//     font-size: 18px;
//   }

//   // Searchable select
//   .address-search {
//     width: 100%;
//     max-width: 272px;
//     min-width: 272px;
//     border: 1px solid $light;
//     box-shadow: 0px 4px 30px rgba($black, 0.08);
//     border-radius: 5px;
//   }
//   .searchable_select__control {
//     margin: 10px;
//     padding: 9px 16px;
//     border: 1px solid #DFDFDF;
//     border-radius: 4px;
//     &:hover {
//       border-color: $primary;
//     }
//   }
//   .searchable_select__value-container {
//     padding: 0;
//   }
//   .searchable_select__placeholder {
//     font-size: 14px;
//   }
//   .searchable_select__input-container {
//     margin: 0;
//     padding: 0;
//   }
//   .searchable_select__option {
//     padding: 5px 10px;
//     white-space: nowrap;
//     text-overflow: ellipsis;
//     overflow: hidden;
//     cursor: pointer;
//     &--is-selected,
//     &--is-focused {
//       background-color: $border-color;
//       color: $dark;
//     }
//   }

//   .searchable_select__indicators {
//     position: absolute;
//     left: auto;
//     right: 15px;
//     top: 50%;
//     transform: translateY(-50%);
//     color: $dark;

//     svg {
//       display: none;
//     }

//     &::after {
//       content: "\e907";
//       font-family: $icomoon-font-family;
//       font-size: 18px;
//       color: $primary;
//     }
//   }
// }

/** ---------------------------------------- CUSTOM SELECT ---------------------------------------- **/
.custom_select__menu-list {
  z-index: 999;
  @include media(down, $breakpoint-lg) {
    max-height: 140px !important;
  }
  .custom_select__option {
    @include media(down, $breakpoint-lg) {
@include fs-16;
padding: 8px;
    }
    &:hover,
    &.custom_select__option--is-focused,
    &.custom_select__option--is-selected {
      background: $border-color;
      color: $dark;
    }
  }
}

.react-select,
.react-multi-select {
  .custom_select__control {
    border-color: $light;
    box-shadow: none;
  }

  &:hover,
  &:focus-visible {
    outline: 0;
  }
}

.custom_select__control {
  padding-right: 42px;
  @extend .form-control;
  box-shadow: none;
  &:hover // &.custom_select__control--is-focused

    {
    border-color: $dark !important;
    box-shadow: none;
  }

  .custom_select__value-container {
    padding: 0;
  }

  .custom_select__indicator-separator {
    display: none;
  }

  .custom_select__single-value {
    color: $dark;
    // padding-right: 18px;
    line-height: 1.2;
  }

  .custom_select__indicators {
    position: absolute;
    top: 50%;
    right: 10px;
    @include css3-prefix(transform, translateY(-50%));
  }

  .custom_select__clear-indicator,
  .custom_select__dropdown-indicator {
    color: $dark;

    svg {
      display: none;
    }

    &::after {
      content: "\e913";
      font-family: $icomoon-font-family;
      font-size: 20px;
      line-height: normal;
      cursor: pointer;
    }
  }

  .custom_select__clear-indicator {
    margin-right: 3px;
    padding: 0;

    &::after {
      content: "\e904";
      font-size: 10px;
    }
  }

  .custom_select__indicator {
    padding: 0;
  }

  .custom_select__input-container,
  .css-b8ldur-Input {
    line-height: 1.4;
    margin: 0;
    padding: 0;
  }
}

// Number react select
.number-select {
  .custom_select__control {
    height: 34px;
    min-height: 34px;
    max-width: 75px;
    width: 75px;
    padding: 6px 8px;
    // margin: 0 auto;
    border: 1px solid $dark;
    border-radius: 5px;
    box-shadow: none;
  }

  .custom_select__single-value,
  .custom_select__placeholder {
    margin: 0;
    color: $dark;
    font-weight: $font-medium;
    @include fs-14;
    line-height: 22px;
  }

  .custom_select__indicators {
    right: 3px;

    &::after {
      @include media(up, $breakpoint-xxl) {
        font-size: 18px;
      }
    }
  }

  .custom_select__input {
    font-size: 14px !important;
    line-height: 12px !important;
    color: $dark !important;
    font-weight: $font-medium !important;
    min-width: 30px !important;

    @include media(down, $breakpoint-xxl) {
      font-size: 12px !important;
    }
  }

  .custom_select__dropdown-indicator {
    line-height: normal;

    &::after {
      font-size: 16px;
    }
  }

  .custom_select__value-container {
    margin-top: -1px;
  }

  .custom_select__indicator {
    padding: 0;
  }

  .custom_select__input-container,
  .css-b8ldur-Input {
    font-size: 8px;
    line-height: normal;
    margin: 0;
    padding: 0;
  }
}

// Creatable menu
.custom_select__menu-portal {
  z-index: 9999 !important;

  .custom_select__menu {
    margin: 4px 0 0;
    border-radius: 5px;
    overflow: hidden;
    max-width: 75px;
  }

  .custom_select__menu-list {
    max-height: 120px;
    padding: 0;

    .custom_select__option {
      padding: 6px 10px;
      @include fs-14;
    }
  }

  .custom_select__menu-notice {
    @include fs-14;
    text-align: left;
  }
}

// Address Select
.address-select {
  .custom_select__control {
    border-color: $light;
    box-shadow: none;
  }

  &:hover,
  &:focus-visible {
    outline: 0;
  }

  .custom_select {
    &__control {
      max-height: 40px;
      padding: 0 50px 0 10px;
      border-color: $border-color;
    }

    &__placeholder,
    &__single-value {
      @include fs-16;
    }

    &__indicator-separator {
      // display: inline-block;
    }

    &__indicators {
      right: 10px;
    }

    &__option {
      padding: 5px 10px;
      // white-space: nowrap;
      // text-overflow: ellipsis;
      // overflow: hidden;
      cursor: pointer;
      @include fs-16;

      &--is-selected,
      &--is-focused {
        background-color: $border-color;
        color: $dark;
      }
    }
  }
}

// Multiselect
.react-multi-select {
  .custom_select {
    &__control {
      height: auto;
      min-height: 56px;
      padding: 10px 40px 10px 20px;

      @include media(down, $breakpoint-md) {
        padding: 10px 45px 10px 20px;
      }
    }

    &__multi-value {
      background-color: transparent;
      border: 1px solid $light;
      border-radius: 70px;
      @include fs-16;
      padding: 4px 8px;
      // margin: 0 5px 0 0;
      font-weight: $font-regular;
    }

    &__multi-value__label {
      padding: 0;
    }

    &__multi-value__remove {
      margin-left: 10px;
      padding: 0;

      &:hover {
        background-color: transparent;

        &::after {
          color: $danger;
        }
      }

      svg {
        display: none;
      }

      &::after {
        content: "\e904";
        font-family: $icomoon-font-family;
        font-size: 10px;
        cursor: pointer;
      }
    }
  }
}

//pagination select
.pagination-select {
  .react-select {
    margin: 0 6px;

    .custom_select__control {
      border-color: $border-color;
      min-height: inherit;
      @include fs-16;
      width: 66px;
      padding: 6px 10px;
      @include media(down, $breakpoint-md) {
        height: 30px;
      }
    }

    .custom_select__indicators {
      right: 10px;
      margin-top: -1px;

      @include media(down, $breakpoint-md) {
        right: 8px;
      }
    }

    .custom_select__single-value {
      @include fs-16;
      line-height: 1;
    }

    .custom_select__value-container {
      margin-top: -6px;
      @include media(down, $breakpoint-md) {
        margin-top: -4px;
      }
      .custom_select__input-container {
        font-size: inherit;

        input {
          position: absolute;
        }
      }
    }

    .custom_select__dropdown-indicator {
      color: $dark;

      &::after {
        margin-top: 4px;
      }
    }
  }
}