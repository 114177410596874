.checkbox-select {
  .custom_select__menu-list {

    .custom_select__option--is-focused,
    .custom_select__option--is-selected,
    .custom_select__option {
      background-color: transparent;
    }
  }
}

.option-views {
  margin-bottom: 90px;
}

.program-select {
  max-height: 330px;
  overflow: auto;

  .form-check {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.search-bar {
  .btn {
    flex: 0 0 115px;

    @include media(down, $breakpoint-xl) {
      flex: 0 0 80px;
    }

    @include media(down, $breakpoint-md) {
      flex: 0 0 100%;
      width: 100%;
      margin-left: 0;
    }
  }
}

.custom-switch {
  .custom-control-input {
    &:focus {
      ~.custom-control-label {
        &::before {
          box-shadow: none !important;
          border: 1px solid $gray;
        }
      }
    }

    &:checked {
      ~.custom-control-label {
        &::before {
          border-color: $secondary;
          background-color: $secondary;
          border-radius: 32px;
          box-shadow: none;
        }

        &::after {
          transform: translateX(1.7rem);
          top: calc(0.125rem + 3px);
          left: calc(-2.25rem + 0px);
        }
      }
    }
  }

  .custom-control-label {
    box-shadow: none;

    &::before {
      width: 48px;
      height: 24px;
      border-radius: 32px;
    }

    &::after {
      width: 18px;
      height: 18px;
      top: calc(0.125rem + 3px);
      left: calc(-2.25rem + 3px);
    }
  }
}

.custom-control {
  min-height: 30px !important;
}