// Bootstrap Override Variable File
@import "helpers/variable";

// Default Bootstrap scss File no need to change
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

// CUSTOM FILES
@import "helpers/import.scss";
@import "component/all.scss";
@import "pages/pages.scss";

// Variable
// :root {
//   --white:          #{$white};
//   --black:          #{$black};
//   --primary:        #{$primary};
//   --secondary:      #{$secondary};
//   --success:        #{$success};
//   --info:           #{$info};
//   --warning:        #{$warning};
//   --danger:         #{$danger};
//   --light:          #{$light};
//   --dark:           #{$dark};
//   --grey:           #{$grey};
//   --border-color:   #{$border-color};
// }
body {
  background: $body-bg;
  font-family: $font-family-base;
  @include text($font-size-base, $body-color, $line-height-base, $font-regular);
}

html,
body {
  height: 100%;
}

// LINKS
a {
  text-decoration: $link-decoration;
  color: $link-color;
  cursor: pointer;
  outline: 0;
  @include css3-prefix(transition, $transition-base);

  &:hover,
  &:focus {
    text-decoration: none;
    outline: 0;
    color: $link-hover-color;
  }
}

// HELPER CLASS
p {
  margin: 0 0 $paragraph-margin-bottom;
  @include text(14px, $dark, 1.4, null);
}

img {
  max-width: 100%;
}

picture {
  display: inline-block;
  height: 100%;
  width: 100%;
  vertical-align: top;
}

// LAYOUT
body,
html {
  height: 100% !important;
}

// Custom Scroll with CSS only Supported Browser Chrome, Safari and Firefox
* {
  scrollbar-color: rgba($black, 0.3) transparent;
  scrollbar-width: thin;
}

// @media (-webkit-device-pixel-ratio: 1.25) {
//   * {
//     zoom: .97;
//   }
// }

::-webkit-scrollbar-track {
  box-shadow: none;
  -webkit-box-shadow: none;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background-color: rgba(#DFDFDF, 1);
}