/* =========== IMAGE PATH VARIABLE ============= */
$assetPath : "/images";
/* Usage of background image */
//background: url(#{$assetPath}/background.jpg) repeat-x fixed 0 0;

// Color system
$white:    	#fff;
$black:	    #000;
// $primary:   #226BB5; //Older Design
$primary:   #F17834;
$secondary:	#226BB5;
$success:  	#37C689;
$info:     	#8929BD;
$warning: 	#EE7E1C;
$danger:  	#EE2D2D;
$light:   	#DFDFDF;
$dark:      #151A30;
$gray: 		  #9B979B;
$label-color: #8A8C97;
$dark-blue: #263883;

// Body
// Settings for the `<body>` element.
$body-bg: $white;
$body-color: $dark;

// Links
// Style anchor elements.
$link-color: $secondary;
$link-decoration:none;
$link-hover-color:darken($secondary, 50%);
$link-hover-decoration:underline;

// Font
$font-family-base: 'Poppins', sans-serif;
$font-heading: 'Poppins', sans-serif;
$font-size-base: 1rem; // Assumes the browser default, typically `16px` 
$font-custom:16; 
$font-size-lg: $font-size-base * 1.25; //20px
$font-size-sm: $font-size-base * .875; //14px
$font-lighter: lighter;
$font-light: 300;
$font-regular:400;
$font-medium:500;
$font-semibold: 600;
$font-bold: 700;
$font-bolder: bolder;
$line-height-base: 1.25;

$font-weight: ("light": 300, "regular": 400, "medium": 500, "semibold": 600, "bold": 700, "bolder": 900);
// use of font-weight
// font-weight: map-get($font-weight, "regular");

// Style p element.
$paragraph-margin-bottom: 1rem;

// Grid breakpoints
$grid-breakpoints: (xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  tab: 1024px,
  xl: 1200px,
  xxl: 1400px) !default;

// Media BreakPoint
$breakpoint-xxl: 1440px;
$breakpoint-xl: 1200px;
$breakpoint-tab: 1024px;
$breakpoint-lg: 992px;
$breakpoint-md: 768px;
$breakpoint-sm: 576px;
$breakpoint-xs: 414px;

// Container max widths
$container-max-widths: (
  lg: 100%,
  xl: 1280px,
  xxl: 1480px) !default;

// Components
// Define common padding and border radius sizes and more.
$border-width:1px;
$border-color:#ECEEF3;
$border-radius:.25rem;
$border-radius-lg:.3rem;
$border-radius-sm:.2rem;

$rounded-pill:50rem;

$box-shadow-sm:0 .125rem .25rem rgba($black, .075);
$box-shadow:0 .5rem 1rem rgba($black, .15);
$box-shadow-lg:0 1rem 3rem rgba($black, .175);
$box-shadow-0:none;

$caret-width:.3em;
$caret-vertical-align:$caret-width * .85;
$caret-spacing:$caret-width * .85;

$transition-base:all .3s ease-in-out;
$transition-fade:opacity .15s linear;
$transition-collapse:height .35s ease;

// Buttons
$btn-padding-y:               .65rem;
$btn-padding-x:               1rem;
$btn-font-family:             $font-family-base;
$btn-font-size:               $font-size-base;
$btn-line-height:             $line-height-base;
$btn-white-space:             null; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm:            0.5rem;
$btn-padding-x-sm:            0.5rem;
$btn-font-size-sm:            $font-size-sm;

$btn-padding-y-lg:            1.25rem;
$btn-padding-x-lg:            1.25rem;
$btn-font-size-lg:            $font-size-lg;

// Forms
$label-margin-bottom:         .5rem;
$input-padding-y:             1rem;
$input-padding-x:             1rem;
$input-font-family:           $font-family-base;
$input-font-size:             $font-size-base;
$input-font-weight:           $font-regular;

$input-padding-y-sm:          0.5rem;
$input-padding-x-sm:          0.5rem;
$input-font-size-sm:          $font-size-sm;

$input-padding-y-lg:          1.25rem;
$input-padding-x-lg:          1.25rem;
$input-font-size-lg:          $font-size-lg;

$input-height-sm: 2.5rem !default;

// Gradient Style
$fallback:$primary;
$from:$primary;
$to:darken($primary,10);
$direction:225deg;

// Quickly modify global styling by enabling or disabling optional features.
$enable-caret:                                true !default;
$enable-rounded:                              true !default;
$enable-shadows:                              false !default;
$enable-gradients:                            false !default;
$enable-transitions:                          true !default;
$enable-prefers-reduced-motion-media-query:   true !default;
$enable-hover-media-query:                    false !default; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes:                         true !default;
$enable-pointer-cursor-for-buttons:           true !default;
$enable-print-styles:                         true !default;
$enable-responsive-font-sizes:                true !default;
$enable-validation-icons:                     true !default;
$enable-deprecation-messages:                 true !default;

// Spacing
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3),
  ),
  $spacers
);

//Custom spacers
// $spacer: 1rem;
// $spacers: ( 0:0);
// @for $i from 1 through 10 {
//   $spacers: map-merge($spacers, (
//   $i: ($i * $spacer/4)
//   ));
// }